// Ищем все формы
import {ajax} from "../utilites/ajax";

export function iForm(selector = '[data-iform]') {
    const iforms = document.querySelectorAll(selector);
    iforms.forEach(function (form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopPropagation();
            iformProcess(form);
        });
    });
}

// Обрабатываем данные формы
function iformProcess(form) {
    const buttons = form.querySelectorAll('[type="submit"]');
    const tokens = form.querySelectorAll('[value="1"]');
    let jtoken = '';
    let data = new FormData(form);

    iformAlertClear(form);

    //ищем токен формы
    tokens.forEach(function (input) {
        if (input.type === 'hidden' && input.name.length === 32) {
            jtoken = input.name;
        }
    });

    const options = {
        data: JSON.stringify(Object.fromEntries(data)),
        method: 'POST',
        token: jtoken,
        headers: {
            'Cache-Control': 'no-cache',
            //'Content-Type': 'text/plain'
            'Content-Type': 'application/json'
            //'Content-Type': 'application/x-www-form-urlencoded'
            //'Content-Type': 'multipart/form-data'
        },
        responseType: '',
    };

    // Показываем спиннер
    document.documentElement.classList.add('loading');
    // Все кнопки деактивируем
    buttons.forEach(function (button) {
        button.setAttribute('disabled', '1');

    });
    
    // Вызываем функцию отправки данных на сервер
    const xhr = ajax('?option=com_ajax&module=iform&format=json', options);

    xhr.addEventListener("readystatechange", () => {
        // Выполнение запроса не окончено
        if (xhr.readyState !== 4) {
            return;
        }

        if (xhr.status === 200) {
            console.log(xhr.responseText);
            const data = JSON.parse(xhr.responseText);
            console.log(data);
            iformClear(form, data);
            iformAlert(form, data);
            iformUpdate(form, data);
        } else {
            const data = JSON.parse(xhr.responseText);
            //console.log(data);
            iformAlert(form, {
                "data": {
                    "type": "danger",
                    "message": data.message
                },
                "success": false,
                "message": data.message
            });

            // Скрываем спиннер
            document.documentElement.classList.remove('loading');
            // Все кнопки активируем
            buttons.forEach(function (button) {
                button.removeAttribute('disabled');
            });
        }

        // Скрываем спиннер
        document.documentElement.classList.remove('loading');
        // Все кнопки активируем
        buttons.forEach(function (button) {
            button.removeAttribute('disabled');
        });
    });    
}

// Очищаем форму и очищаем форматирование
function iformClear(form, data) {
    const inputs = form.querySelectorAll('input');
    inputs.forEach(function (input) {
        input.classList.remove('uk-form-success', 'uk-form-danger');
    });

    iformAlertClear(form);
}

// Удаляем все сообщения
function iformAlertClear(form) {
    const alerts = form.querySelectorAll('.uk-alert');
    alerts.forEach(function (alert) {
        alert.remove();
    });
}

// Показываем сообщение
function iformAlert(form, data) {
    const alert = document.createElement('div');

    alert.classList.add('uk-alert', 'uk-alert-' + data.data.type);
    alert.setAttribute('role', 'alert');
    alert.innerText = data.message;

    form.appendChild(alert);
}

// Обновляем статусы полей в форме
function iformUpdate(form, data) {
    if (data.data.errors.length !== 0) {
        data.data.errors.forEach(function (error) {
            if (error.key !== 'header') {
                const input = form.querySelector('[name="' + error.key + '"]');
                input.classList.add('uk-form-danger');
            }
        });
    }

    if (data.data.valid.length !== 0) {
        data.data.valid.forEach(function (valid) {
            const input = form.querySelector('[name="' + valid + '"]');
            input.classList.add('uk-form-success');
        });
    }
}