export function ajax(url, opt) {
    const env = {
        data: null,
        method: 'POST',
        token: '',
        headers: {},
        responseType: '',
    };
    const options = Object.assign(env, opt);    
    const xhr = new XMLHttpRequest();
    
    xhr.open(options.method.toUpperCase(), url);

    for (const header in options.headers) {
        xhr.setRequestHeader(header, options.headers[header]);
    }
    
    if (options.token !== '') {
        xhr.setRequestHeader('X-CSRF-Token', options.token);
    }

    xhr.send(options.data);
    
    return xhr;
}