import {iLazy} from './scripts/ilazy';
if (document.querySelector('img[data-srcset]')) {
    iLazy('img[data-srcset]', {}).observe();
}
if (document.querySelector('iframe[data-src]')) {
    iLazy('iframe[data-src]', {}).observe();
}

//**************************//

import {iForm} from "./scripts/iform";
iForm('[data-iform]');

//**************************//